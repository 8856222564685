import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Menu, Col, Row, Icon } from "antd"
import { globalHistory } from "@reach/router"

const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

const DeskMenu = () => {
  const { location } = globalHistory

  return (
    <Menu
      className='nav'
      theme="light"
      mode="horizontal"
      selectedKeys={[location.pathname.split('/')[1]]}
      style={{ lineHeight: '62px', borderBottom: 0 }}
      // overflowedIndicator={<Icon type="menu" />}
    >
      <Menu.Item key="pricing" onClick={() => document.getElementById('pricing').scrollIntoView()}>
        Pricing
      </Menu.Item>
      <Menu.Item key="features" onClick={() => document.getElementById('features').scrollIntoView()}>
        Features
      </Menu.Item>
      {/* <Menu.Item key="privacy">
        <Link to="/privacy">Privacy</Link>
      </Menu.Item> */}
      <Menu.Item key="account">
        <a href='https://mturkpro.com/myaccount'>My Account</a>
      </Menu.Item>
    </Menu>
  )
}

const MobileMenu = () => {
  const { location } = globalHistory

  return (
    <Menu
      className='mobile-nav'
      theme="light"
      mode="horizontal"
      selectedKeys={[location.pathname.split('/')[1]]}
      style={{ lineHeight: '62px', borderBottom: 0 }}
    >
      <SubMenu title={<Icon type="menu" style={{ fontSize: 30, verticalAlign: 'middle' }} />}>
        <MenuItemGroup>
          <Menu.Item key="pricing" onClick={() => document.getElementById('pricing').scrollIntoView()}>
            Pricing
          </Menu.Item>
          <Menu.Item key="features" onClick={() => document.getElementById('features').scrollIntoView()}>
            Features
          </Menu.Item>
          {/* <Menu.Item key="privacy">
            <Link to="/privacy">Privacy</Link>
          </Menu.Item> */}
          <Menu.Item key="account">
            <a href='https://mturkpro.com/myaccount'>My Account</a>
          </Menu.Item>
        </MenuItemGroup>
      </SubMenu>
    </Menu>
  )
}

// const Header = ({ siteTitle, location }) => {
const Header = (props) => (
  <header className='menu-header sticky'
    // className='dark-background'
  >
    <Row type="flex" justify="start">
      <Col id='icon-col' span={7} offset={1}>
        <Link className="site-header site-font" to="/">
          <img className='header-logo' src='/icons/icon-48x48.png' alt='MTurk Pro Logo' />
          <span style={{ fontSize: 'larger', verticalAlign: 'middle' }}>MTurk Pro</span>
        </Link>
      </Col>
      <Col id='menu-col' span={16} style={{ textAlign: 'center' }}>
        {DeskMenu()}
      </Col>
      {MobileMenu()}
    </Row>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
