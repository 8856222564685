/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby" 
import { Row, Col, Layout, Tooltip } from "antd" 
import Header from "./header"
import ReactGA from 'react-ga'
import { globalHistory } from "@reach/router"
// import CookieConsent, { Cookies } from "react-cookie-consent"
import "./layout.css"

const { Footer } = Layout

// const Main = ({ children }) => {
const Main = ({ style, children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // const [permission, setpermission] = useState(Cookies.get('pp-consent'))
  const permission = true
  const { location } = globalHistory

  if (typeof window === 'undefined') {
      global.window = {}
  }

  if (permission) {
    ReactGA.initialize('UA-148606987-1')
    ReactGA.ga('set', 'anonymizeIp', true)
    ReactGA.ga('set', 'dimension1', 'Premium15')
    // ReactGA.ga('set', 'dimension2', 'Premium10')
    ReactGA.pageview(location.pathname + location.search)
    // console.log('Sent Analytics', location.pathname + location.search)
  }

  return (
    <div className={`${className}`}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      {/* {CookieConsent()} */}
      {/* {!permission && <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="pp-consent"
        style={{ background: "#222" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={(event) => { console.log('Cookie Accept', event); setpermission(true); }}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
      </CookieConsent>} */}
      <Footer style={{ textAlign: 'center' }}>
        <Row>
            <Col span={8}>© {new Date().getFullYear()} MTurk Pro</Col>
            <Col span={8} style={{ alignItems: 'center' }}>{LogoRow()}</Col>
            <Col span={8}>
              <Link to="/privacy">Privacy</Link>
              <Link to="/terms">Terms</Link>
              <a href='mailto:admin@mturkpro.com'>Contact</a>
            </Col>
          </Row>
      </Footer>
    </div>
  )
}

function iconLink({ icon, url, alt, style }) {
  return (
    <Tooltip title={alt}>
      <a href={url}>
        <img className='icon-link' src={icon} alt={alt} style={style} />
      </a>
    </Tooltip>
  )
}

const LogoRow = () => (
  <div>
    {iconLink({ icon: '/media/Facebook Logo.png', url: `https://www.facebook.com/MTurkPro`, alt: 'Follow on Facebook', style: { width: 25, margin: '0 1px' } })}
    {iconLink({ icon: '/media/Discord Logo.png', url: `https://discord.gg/4PBV8aC`, alt: 'Chat on Discord', style: { width: 24, margin: '0 1px' } })}
    {iconLink({ icon: '/media/Youtube Logo.png', url: `https://www.youtube.com/channel/UC-0hYCTHrMntMmIXSwjURxg`, alt: 'Watch on Youtube', style: { width: 30, margin: '0 1px' } })}
  </div>
)

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
